import React from "react"
import type { UserType, AllLayoutsType } from "@lesmills/gatsby-theme-common"
import type { UpdatePaymentPageType } from "../types/UpdatePaymentPageType"
import {
  renderSEO,
  Layout,
  PrivateRoute,
  ROUTES,
  isResellerCustomerUser,
  CHARGIFY_SCRIPT,
} from "@lesmills/gatsby-theme-common"
import { graphql } from "gatsby"

import UpdatePaymentDetail from "../components/UpdatePaymentDetail"

type Props = {|
  pageContext: {
    lang: string,
  },
  data: {
    prismicUpdatePaymentDetailsPage: UpdatePaymentPageType,
    prismicLayout: AllLayoutsType,
  },
|}

const UpdatePaymentDetailPage = (props: Props) => {
  const { pageContext, data } = props || {}
  const scriptsUpdatePaymentDetailPage = [CHARGIFY_SCRIPT]
  if (!data) return null

  const lang = pageContext.unPublishedLang || pageContext.lang
  const { prismicLayout = {}, prismicUpdatePaymentDetailsPage = {} } = data
  const pageContent = prismicUpdatePaymentDetailsPage.data || {}
  const layout = prismicLayout.data || {}

  const renderUpdatePaymentPage = ({ user, checkingSession }: UserType) => (
    <Layout data={layout} lang={lang} user={user} isLoading={checkingSession}>
      {renderSEO(
        pageContent,
        ROUTES(lang).UPDATE_PAYMENT_DETAILS,
        lang,
        scriptsUpdatePaymentDetailPage
      )}
      <UpdatePaymentDetail
        prismicData={pageContent}
        lang={lang}
        user={user}
        layoutData={layout}
        isResellerCustomerUser={isResellerCustomerUser(user)}
      />
    </Layout>
  )

  return (
    <PrivateRoute component={renderUpdatePaymentPage} {...props} lang={lang} />
  )
}

export const query = graphql`
  query($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      data {
        lmod_uace_err {
          text
        }
        required_state_notification {
          raw
        }
        if_applicable {
          text
        }
        plus_tax_label {
          text
        }
        sign_in_button {
          raw
          text
        }
        sign_out_button {
          raw
          text
        }
        logo {
          alt
          url
        }
        ...LayoutUnits
        ...LayoutPaymentForm
        body {
          ...LayoutBodyNavigation
        }
        body1 {
          ...LayoutBody1Navigation
        }
        body3 {
          ...LayoutBody3Countries
        }
        body2 {
          ...LayoutBody2Months
        }
        lmod_gu_err {
          text
        }
      }
    }
    prismicUpdatePaymentDetailsPage(lang: { eq: $lang }) {
      ...UpdatePaymentDetailsPage
    }
  }
`

export default UpdatePaymentDetailPage
